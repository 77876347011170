body {
  margin: 0;
  font-family: 'Lora', serif;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Lora', serif;
  font-family: 'Nunito Sans', sans-serif;
  font-family: 'Jockey One', sans-serif;
}
