.App {
  text-align: center;
  padding: 20px;
  justify-content: center;
  background-color: var(--light);
}
.body {
  font-family: 'Nunito Sans', sans-serif;
}
:root {
  --higlight: #64fadc;
  --dark: #072b66;
  --accent: #addde5;
  --light: #ffffff;
  --ourgrey: #9fa7ac;
  --primary-color:var(--dark);
  --secondary-color:var(--accent);
  --danger-color:var(--higlight);
  --info-color:var(--ourgrey);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--accent);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--light);
}

.App-link {
  color: #61dafb;
}

button {
  height: 50px;
  width: 200px;
  padding: 5px 10px 5px 10px;
  background-color: var(--dark);
  font-size: 30px;
  border: 2px solid var(--accent);
  color:var(--accent);
  border-radius: 12px;
  font-style: normal;
  font-family: 'Nunito Sans', sans-serif;
}
.darkBG{
  background-color: var(--dark);
  color: var(--accent);
  font-family: 'Lora', serif;
  font-size: 15px;
}
.mainHolder{
  gap: 45px;
  padding: 5px 10px 5px 10px;
  align-items: center;
  margin: 20px;
}
.eyeCatch{
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: 70px;
  color:var(--dark);

}
.meatParagraph{
  font-family: 'Lora', serif;
  align-items: center;
  text-align: left;
  font-size: 18px;

}

.cardBlock {
  background-color: var(--light);
  box-shadow: 4px 6px 5px var(--ourgrey);
  width: 90%;
  max-width: 1100px;
  max-height: 1000px;
  min-height: 220px;
  border-radius: 20px;
  align-items: center;
  display: flex;
}

.cardInfo {
  border-radius: 20px;
  font-style: normal;
  font-family: 'Lora', serif;
  font-size: 20px;
  color: black ;
  text-indent: 40;
  text-align:left;
  padding: 5px;
  margin:5;
}

.cardPicture {
  float: left;
  border-radius: 12px;
  max-height: 200px;
  min-height: 100px;
  max-width: 400px;
  min-width: 200px;
}

#cardImg {
  width: 100%;
  height: auto;
}

.homebutton {
  background-color: var(--light);
  max-width: 100%;
}
#homebutton {
  width: 20%;
  height: auto;
  justify-content: left;
}

.LoginHeader {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 45px;
  text-align: center;
}

.LoginSignupPrompts {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  text-align: center;
}

.SignupHeader {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 45px;
  text-align: center;
}

.inputs {
  
}

.fnlnSpacing {
  text-align: center;
  display: flex;
  justify-content: center;
}

button:hover {
  color:var(--higlight);
  border: 3px solid var(--higlight);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.loginOrSignupContainer {
  display: flex;
  gap: 35px;
  margin: 70px auto;
  justify-content: center;
}
.loginOrSignup {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
  width: 140px;
  padding: 5px 10px 5px 10px;
  background-color: var(--dark);
  font-size: 30px;
  border: 2px solid var(--accent);
  color:var(--accent);
  border-radius: 12px;
  font-style: normal;
  font-family: 'Nunito Sans', sans-serif;

}

.onlyForSignup {
  display: flex;
  gap: 35px;
  justify-content: center;
  text-align: center;
}

.loginOrSignup:hover {
    color:var(--higlight);
    border: 2px solid var(--higlight);
}

.navbarlayout {
  display: flex;
  gap: 20px;
  justify-content: center;
}

#navbartext {
  color: var(--dark);
  font-size: 20px;
}
